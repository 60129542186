import React from "react"
import Layout from "../components/layout"

export default function Kontakt() {
  return (
  <Layout>
    <div className='text-block' style={{marginTop: `30px`}}>
    
      <div className='contact-map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.221201510812!2d17.0803841577327!3d48.144540553739525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c8bc2b45964f5%3A0xc9da5e0347325f41!2sApartm%C3%A1ny%20Lafranconi!5e0!3m2!1ssk!2ssk!4v1596140723016!5m2!1ssk!2ssk" width="100%" height="100%" frameborder="0" style={{border:`0`}} allowfullscreen="" aria-hidden="false" tabindex="0" title="map"></iframe>
      </div>

      <div className='contact-block'>
        <div className='contact-title'>
          <p className='main-title contact-title-align' style={{fontSize: `30px`, marginTop: `-10px`}}>KONTAKT</p>
          <p className='sub-title contact-title-align' style={{fontSize: `20px`, marginTop: `-30px`}}>Tešíme sa na Vašu návštevu</p>
        </div>
        <div className='contact-elements'>
          <div>
            <div className='contact-item'>Apartmány Lafranconi</div>
            <div>Nábrežie arm. gen. L. Svobodu 40<br/>811 02 Bratislava</div>
          </div>
          <div>
            <div className='contact-item'>GPS súradnice</div>
            <div>48.144389, 17.080288</div>
          </div>
          <div>
            <div className='contact-item'>Telefónny kontakt</div>
            <div>+421 905 790 589<br/>+421 905 790 591</div>
          </div>
          <div>
            <div className='contact-item'>e-mail</div>
            <div>csibaturist@centrum.sk</div>
          </div>
          <div>
            <div className='contact-item'>Recepcia</div>
            <div>Apartmánový obchod<br/>Pon - Pia: 6:00 - 18:00<br/>Zvyšný čas tel. kontakt</div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  );
}
    
  